<template>
  <div class="mb-1">
    <label class="label mb-2">Substrings</label>
    <div class="columns is-multiline mx-1">
      <div
        v-for="locale in supportedLocales"
        :key="locale.countryCode"
        @click="currentLocale = locale.localeCode"
        class="has-flag-button"
        :style="{ background: locale.localeCode === currentLocale ? '#BDBDBD' : '' }"
      >
        <country-flag :code="locale.countryCode"></country-flag>
      </div>
    </div>

    <div class="control mt-1">
      <template v-for="(value, index) in currentLocaleSubstrings">
        <div :key="`${currentLocale}-${index}`" class="is-flex is-flex-direction-row is-flex is-align-items-center box">
          <div class="mr-1">
            <div class="label">Substring</div>
            <input class="input" type="text" v-model="currentLocaleSubstrings[index]" @input="setDirty"/>
            <div class="label mt-1">Url</div>
            <input class="input" type="text" v-model="currentLocaleLinks[index]" @input="setDirty"/>
          </div>
          <b-button type="is-danger" class="p-1 mb-1" rounded @click="removeValuesFromIndex(index)">
            <div class="is-flex is-justify-content-center is-align-items-center">
              <close-icon class="svg-icon" style="height: 24px; width: 24px; stroke: white"></close-icon>
            </div>
          </b-button>
        </div>
      </template>
      <b-button type="is-success" class="mt-1" @click="addValue" expanded>ADD SUBSTRING</b-button>
      <b-button type="is-success" class="mt-1" @click="save" expanded :disabled="isSaved"
                v-if="currentLocaleSubstrings.length">
        <template v-if="isSaved"> SAVED</template>
        <template v-else> SAVE</template>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CountryFlag from "@/web/components/shared/CountryFlag";
import CloseIcon from "@/assets/icon_close.svg";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

const DEFAULT_LANG = "en";

export default {
  name: "FieldCheckboxSubstrings",

  props: {
    currentSubstrings: {
      type: Object,
      required: false,
    },
  },

  components: { CountryFlag, CloseIcon },

  data() {
    return {
      currentLocale: DEFAULT_LANG,
      values: this.currentSubstrings || {},
      currentLocaleSubstrings:
        (this.currentSubstrings && this.currentSubstrings[DEFAULT_LANG][FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS]) || [],
      currentLocaleLinks:
        (this.currentSubstrings && this.currentSubstrings[DEFAULT_LANG][FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS]) || [],
      isSaved: !!this.currentSubstrings,
    };
  },

  computed: {
    ...mapGetters("locales", ["supportedLocales"]),

    FormBuilderConstants: () => FormBuilderConstants,
  },

  methods: {
    addValue() {
      Object.values(this.values).forEach(it => {
        it[FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS].push("");
        it[FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS].push("");
      });
      this.setDirty();
    },

    removeValuesFromIndex(index) {
      Object.values(this.values).forEach(it => {
        it[FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS].splice(index, 1);
        it[FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS].splice(index, 1);
      });
      this.setDirty();
    },

    hasError() {
      return !Object.values(this.values)
        .map(array => {
          let allEmpty =
            array[FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS].every(it => !it) &&
            array[FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS].every(it => !it);
          let allFilled =
            array[FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS].every(it => !!it) &&
            array[FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS].every(it => !!it);
          return allEmpty || allFilled;
        })
        .every(it => it);
    },

    save() {
      if (!this.hasError()) {
        this.isSaved = true;
        const result = Object.entries(this.values)
          .filter(([key, value]) => {
            return (
              value[FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS].length &&
              value[FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS].every(it => !!it) &&
              value[FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS].length &&
              value[FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS].every(it => !!it)
            );
          })
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        this.$emit("input", result);
      } else {
        this.$root.openErrorModal(
          "Something is wrong, please check all values in all languages. Every filled language has to have equal options count at correct indexes. Filled language can't have empty values.",
        );
      }
    },

    setDirty() {
      this.isSaved = false;
    },
  },

  watch: {
    currentLocale: {
      immediate: true,
      handler: function(newValue, oldValue) {
        if (!oldValue) {
          this.values[newValue] = {
            [FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS]: this.currentLocaleSubstrings,
            [FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS]: this.currentLocaleLinks,
          };
        } else {
          this.values[oldValue] = {
            [FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS]: this.currentLocaleSubstrings,
            [FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS]: this.currentLocaleLinks,
          };

          if (!this.values[newValue]) {
            this.values[newValue] = {
              [FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS]: new Array(this.currentLocaleSubstrings.length).fill(""),
              [FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS]: new Array(this.currentLocaleSubstrings.length).fill(""),
            };
          }
          this.currentLocaleSubstrings = this.values[newValue][FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS];
          this.currentLocaleLinks = this.values[newValue][FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS];
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.has-flag-button {
  padding: 4px;
  cursor: pointer;
  border: #828282 1px solid;
  border-radius: 4px;
  margin: 2px;
  background-clip: border-box;

  &:hover {
    background: #c0c0c0;
  }

  img {
    display: block;
    width: 26px;
    height: auto;
  }
}
</style>
