<template>
  <div class="container is-fluid mt-3 pb-5">
    <separator class="separator is-primary"></separator>
    <div class="is-size-2 has-text-weight-bold has-text-primary has-text-centered mb-2">Custom Form Builder</div>

    <div class="columns">
      <!-- LEFT COLUMN - LAUNCH CONTROL CENTER -->
      <div class="column is-one-fifth">
        <div class="box">
          <div class="has-text-centered has-text-weight-bold is-size-4 mb-2">Launch Control Center</div>
          <p class="mb-1 is-size-7">
            Logged in as admin:
            <span class="has-text-weight-bold" :class="{ 'has-text-danger': !isAdmin, 'has-text-success': isAdmin }">{{ isAdmin }}</span>
          </p>
          <b-button v-if="!isAdmin" type="is-primary" @click="openLoginModal" class="mb-1" expanded>LOG IN</b-button>
          <p class="mb-1 is-size-7">
            Locale selection enabled:
            <span
              class="has-text-weight-bold"
              :class="{ 'has-text-danger': !localeSelectionEnabled, 'has-text-success': localeSelectionEnabled }"
              >{{ localeSelectionEnabled }}</span
            >
          </p>
          <div v-if="!localeSelectionEnabled" class="is-size-8 has-text-danger mb-1">
            If you want to enable it, you need to visit pan pingwin and remove the 'language' property from event configuration.
          </div>
        </div>

        <div class="box">
          <div>
            <div class="has-text-centered has-text-weight-bold is-size-4 mb-2">{{ $t("custom_form_builder.form_settings") }}</div>

            <p class="mb-1 is-size-7">
              {{ $t("custom_form_builder.form_type_label") }}
            </p>

            <dropdown-select-wrapper
              :unselectable="false"
              :items="customFormTypes"
              :item-value-function="item => item.value"
              :item-label-function="item => item.label"
              v-model="formType"
            ></dropdown-select-wrapper>

            <event-ticket-ids-picker
              class="mt-2"
              v-if="formType === FormBuilderConstants.FORM_TYPES.EVENT_TICKETS"
              v-model="selectedEventTicketIds"
            ></event-ticket-ids-picker>

            <div class="field mt-3 mb-1">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="includeTermsAndGdpr"></checkbox>
                <span class="is-size-8" style="margin-top: 2px" @click="includeTermsAndGdpr = !includeTermsAndGdpr">
                  {{ $t("custom_form_builder.form_include_gdpr_and_terms") }}
                </span>
              </label>
            </div>

            <div class="field mt-3 mb-1">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="socialLoginVisible"></checkbox>
                <span class="is-size-8" style="margin-top: 2px" @click="socialLoginVisible = !socialLoginVisible">
                  {{ $t("custom_form_builder.form_social_login_visible") }}
                </span>
              </label>
            </div>

            <!--FACEBOOK APP ID-->
            <div class="field mt-3 mb-1">
              <label class="label has-text-primary">{{ $t("custom_form_builder.form_facebook_app_id") }}</label>
              <div class="control">
                <input
                  class="input is-simple"
                  type="text"
                  :placeholder="$t('custom_form_builder.form_facebook_app_id')"
                  v-model="facebookAppId"
                />
              </div>
            </div>

            <!--APPLE CLIENT ID-->
            <div class="field mt-3 mb-1">
              <label class="label has-text-primary">{{ $t("custom_form_builder.form_apple_client_id") }}</label>
              <div class="control">
                <input
                  class="input is-simple"
                  type="text"
                  :placeholder="$t('custom_form_builder.form_apple_client_id')"
                  v-model="appleClientId"
                />
              </div>
            </div>

            <div class="field mt-3 mb-1">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="disableTitle"></checkbox>
                <span class="is-size-8" style="margin-top: 2px" @click="disableTitle = !disableTitle">
                  {{ $t("custom_form_builder.form_title_disabled_label") }}
                </span>
              </label>
            </div>

            <field-label-editor
              v-if="!disableTitle"
              @input="updateTitleTranslations"
              :title="$t('custom_form_builder.form_title_label')"
              :current-label-translations="titleTranslations"
            ></field-label-editor>

            <div class="field mt-3 mb-1">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="disableSubtitle"></checkbox>
                <span class="is-size-8" style="margin-top: 2px" @click="disableSubtitle = !disableSubtitle">
                  {{ $t("custom_form_builder.form_subtitle_disabled_label") }}
                </span>
              </label>
            </div>

            <field-label-editor
              v-if="!disableSubtitle"
              @input="updateSubtitleTranslations"
              :title="$t('custom_form_builder.form_subtitle_label')"
              :current-label-translations="subtitleTranslations"
            ></field-label-editor>
          </div>
        </div>

        <div class="box">
          <div class="has-text-centered has-text-weight-bold is-size-4 mb-2">Fields order</div>
          <div v-for="field in fields" :key="field[FormBuilderConstants.FIELD_PROPERTIES.ORDER]">
            <div class="columns box is-variable is-vcentered is-1" style="padding: 2px">
              <div class="column is-narrow">
                <div class="is-flex is-flex-direction-column is-justify-content-flex-end">
                  <div
                    class="button is-primary arrow-button"
                    @click="moveFieldUp(field)"
                    v-if="field[FormBuilderConstants.FIELD_PROPERTIES.ORDER] > 0"
                  >
                    <arrow-up style="fill: white"></arrow-up>
                  </div>
                  <div
                    class="button is-primary arrow-button mt-1"
                    @click="moveFieldDown(field)"
                    v-if="field[FormBuilderConstants.FIELD_PROPERTIES.ORDER] < fields.length - 1"
                  >
                    <arrow-down style="fill: white"></arrow-down>
                  </div>
                </div>
              </div>
              <div class="column is-size-7">
                <div>
                  <div class="fields-order-text">
                    <span class="has-text-weight-bold">id:</span>
                    <span> {{ field[FormBuilderConstants.FIELD_PROPERTIES.ID] }} </span>
                  </div>
                  <div class="fields-order-text">
                    <span class="has-text-weight-bold">type:</span>
                    <span>{{ field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] }}</span>
                  </div>
                  <div class="fields-order-text">
                    <span class="has-text-weight-bold">order:</span>
                    <span>{{ field[FormBuilderConstants.FIELD_PROPERTIES.ORDER] }}</span>
                  </div>
                </div>
              </div>
              <div
                class="column is-narrow"
                v-if="field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] !== FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS"
              >
                <div class="is-flex is-flex-direction-column">
                  <div class="button is-warning arrow-button" @click="editField(field)">
                    <edit-icon class="svg-icon"></edit-icon>
                  </div>
                  <div class="button is-danger arrow-button mt-1" style="padding: 2px !important" @click="removeField(field)">
                    <trash-bin-icon class="svg-icon"></trash-bin-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CENTER COLUMN - BUILDER -->
      <div class="column is-two-fifths" :key="`forceRefreshKey-${forceRefreshKey}`">
        <div v-for="field in filteredFields" :key="field.order">
          <custom-field-editor
            :ref="`field-${field.order}`"
            :field="field"
            :order="field.order"
            :all-fields="filteredFields"
            @input="onFieldUpdate"
          ></custom-field-editor>
        </div>

        <b-button type="is-primary" class="my-3" expanded @click="addNewField">ADD FIELD</b-button>
      </div>

      <!-- RIGHT COLUMN - PREVIEW -->
      <div class="column is-two-fifths">
        <div class="box">
          <div class="box">
            <div class="is-size-4 has-text-centered mb-2 has-text-weight-bolder">Preview settings</div>
            <div class="field mb-1">
              <label class="checkbox consents">
                <checkbox class="checkbox-input" v-model="includeRegisterForm"></checkbox>
                <span class="is-size-8" style="margin-top: 2px" @click="includeRegisterForm = !includeRegisterForm"
                  >Include register fields</span
                >
              </label>
            </div>
            <div class="field mb-1" v-if="localeSelectionEnabled">
              <div class="has-text-weight-bold">Locale:</div>
              <nav-locales class="auth-locales-container"></nav-locales>
            </div>

            <b-button :type="jsonConfigVisible ? 'is-danger' : ''" class="mt-3" @click="jsonConfigVisible = !jsonConfigVisible" expanded>
              {{ jsonConfigVisible ? "HIDE CONFIG" : "SHOW CONFIG" }}
            </b-button>

            <div class="box mt-1 config-preview-box" v-if="jsonConfigVisible">
              {{ stringifiedJsonConfig }}
            </div>

            <b-button :type="specificIdsVisible ? 'is-danger' : ''" class="mt-3" @click="specificIdsVisible = !specificIdsVisible" expanded>
              {{ specificIdsVisible ? "HIDE SPECIFIC FIELDS" : "SHOW SPECIFIC FIELDS" }}
            </b-button>

            <template v-if="specificIdsVisible">
              <div class="has-text-weight-bold mt-3">Builder fields ids:</div>
              <div class="box mt-1 config-preview-box">
                <div>{{ fieldIds }}</div>
              </div>

              <div class="has-text-weight-bold mt-1">Current event fields ids:</div>
              <div class="box mt-1 config-preview-box">
                <div>{{ currentFields }}</div>
              </div>
            </template>

            <b-button type="is-primary" :disabled="!isAdmin" class="mt-3" @click="tryToDeploy" expanded>DEPLOY</b-button>
            <template v-if="error">
              <p class="help is-danger">{{ error }}</p>
            </template>
          </div>
          <div class="box">
            <b-button :type="previewVisible ? 'is-danger' : 'is-primary'" class="" @click="previewVisible = !previewVisible" expanded>
              {{ previewVisible ? "HIDE PREVIEW" : "SHOW PREVIEW" }}
            </b-button>
            <custom-register-form
              class="mt-2"
              v-if="previewVisible"
              :current-locale="currentLocale"
              :form-data="currentConfig"
              :include-register-form="includeRegisterForm"
            ></custom-register-form>
            <div class="has-text-centered mt-2" v-else>Preview is disabled, be careful it might explode 💥</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

import { mapActions, mapGetters, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import CustomRegisterForm from "@/web/components/forms/CustomRegisterForm";
import CustomFieldEditor from "@/web/views/dev/formbuilder/CustomFieldEditor";
import FieldLabelEditor from "@/web/views/dev/formbuilder/FieldLabelEditor";
import Checkbox from "@/shared/components/form/Checkbox";
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";
import NavLocales from "@/web/components/locale/NavLocales";
import Separator from "@/web/components/shared/Separator";
import ArrowDown from "@/assets/arrow-down.svg";
import ArrowUp from "@/assets/arrow-up.svg";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import EditIcon from "@/assets/icons/edit_2.svg";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import EventTicketIdsPicker from "@/web/components/admin/subpanels/modules/views/EventTicketIdsPicker";

export default {
  name: "CustomFormBuilder",
  components: {
    EventTicketIdsPicker,
    DropdownSelectWrapper,
    CustomRegisterForm,
    CustomFieldEditor,
    FieldLabelEditor,
    Checkbox,
    NavLocales,
    Separator,
    ArrowDown,
    ArrowUp,
    TrashBinIcon,
    EditIcon,
  },
  mixins: [RequestMixin, AuthorizedRouteMixin],

  data() {
    return {
      disableTitle: false,
      disableSubtitle: false,
      titleTranslations: {},
      subtitleTranslations: {},
      includeTermsAndGdpr: true,
      socialLoginVisible: true,
      appleClientId: null,
      facebookAppId: null,
      formType: "custom_register_form",
      selectedEventTicketIds: [],
      localeField: "",
      fields: [
        {
          id: FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS,
          type: FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS,
          order: 0,
        },
      ],
      //FORM PREVIEW
      previewVisible: false,
      includeRegisterForm: true,
      jsonConfigVisible: false,
      specificIdsVisible: false,
      //Field ids
      fieldIdsSent: false,
      //Other
      forceRefreshKey: 0,
    };
  },

  methods: {
    ...mapActions("currentUser", ["fetchUserMeta"]),
    ...mapActions("adminPanel", ["updateCustomRegisterForm", "updateCustomTicketsForm"]),

    addNewField() {
      this.fields.push({
        [FormBuilderConstants.FIELD_PROPERTIES.ID]: "",
        [FormBuilderConstants.FIELD_PROPERTIES.ORDER]: this.fields.length,
        [FormBuilderConstants.FIELD_PROPERTIES.TYPE]: null,
      });
    },

    moveFieldUp(field) {
      const fieldOrder = field[FormBuilderConstants.FIELD_PROPERTIES.ORDER];
      if (fieldOrder > 0) {
        this.fields = this.swapItems(this.fields, fieldOrder, fieldOrder - 1);
      }
      this.forceRefreshKey = this.forceRefreshKey + 1;
    },

    moveFieldDown(field) {
      this.previewVisible = false;
      const fieldOrder = field[FormBuilderConstants.FIELD_PROPERTIES.ORDER];
      if (fieldOrder < this.fields.length - 1) {
        this.fields = this.swapItems(this.fields, fieldOrder, fieldOrder + 1);
      }
      this.forceRefreshKey = this.forceRefreshKey + 1;
    },

    removeField(field) {
      if (this.checkIfFieldIsRemovable(field)) {
        this.fields.splice(this.fields.indexOf(field), 1);
      } else {
        this.$root.openErrorModal(this.$t("custom_form_builder.field_error_id_type_rule_dependency_remove"));
      }
    },

    editField(field) {
      const scrollArgs = { behavior: "smooth", block: "start" };
      this.$refs[`field-${field.order}`][0].$el.scrollIntoView(scrollArgs);
    },

    swapItems(arr, a, b) {
      arr[a] = arr.splice(b, 1, arr[a])[0];
      arr[a][FormBuilderConstants.FIELD_PROPERTIES.ORDER] = a;
      arr[b][FormBuilderConstants.FIELD_PROPERTIES.ORDER] = b;
      return arr;
    },

    checkIfFieldIsRemovable(fieldToCheck) {
      if (this.fields && this.fields.length) {
        return this.fields.every(field => {
          let rules = field[FormBuilderConstants.FIELD_PROPERTIES.RULES];
          if (rules && rules.length) {
            return rules.every(rule => rule[FormBuilderConstants.RULE_PROPERTIES.FIELD_ID] !== fieldToCheck.id);
          } else {
            return true;
          }
        });
      } else {
        return true;
      }
    },

    openLoginModal() {
      this.$root.openLoginModal();
    },

    onFieldUpdate(updatedField) {
      this.$set(this.fields, updatedField[FormBuilderConstants.FIELD_PROPERTIES.ORDER], updatedField);
    },

    tryToDeploy() {
      this.$root.openConfirmationModal(
        this.$t("custom_form_builder.deploy_confirmation_title"),
        this.$t("custom_form_builder.deploy_confirmation_description"),
        {
          confirm: () => {
            this.deploy();
          },
          cancel: () => {
            /** no-op **/
          },
        }
      );
    },

    updateTitleTranslations(translations) {
      this.titleTranslations = { ...translations };
    },

    updateSubtitleTranslations(translations) {
      this.subtitleTranslations = { ...translations };
    },
  },

  requests: {
    async deploy() {
      if (this.formType === FormBuilderConstants.FORM_TYPES.REGISTER_FORM) {
        let config = {
          [LpConfigConstants.ROOT_FIELDS.REGISTER_FORM]: this.currentConfig,
        };
        await this.updateCustomRegisterForm(config).then(result => {
          this.$root.openInfoModal({
            title: this.$t("common.success_modal_title"),
            message: this.$t("custom_form_builder.deploy_success_message"),
          });
        });
      } else if (this.formType === FormBuilderConstants.FORM_TYPES.EVENT_TICKETS) {
        return this.updateCustomTicketsForm({ formData: this.currentConfig, eventTicketIds: this.selectedEventTicketIds }).then(result => {
          this.$root.openInfoModal({
            title: this.$t("common.success_modal_title"),
            message: this.$t("custom_form_builder.deploy_success_message"),
          });
        });
      }
    },
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("auth", ["isAdmin"]),
    ...mapGetters("locales", ["localeSelectionEnabled", "currentLocale"]),
    ...mapState("currentUser", { currentFields: "userMeta" }),

    FormBuilderConstants: () => FormBuilderConstants,

    customFormTypes() {
      return [
        { value: FormBuilderConstants.FORM_TYPES.REGISTER_FORM, label: this.$t("custom_form_builder.form_type_register_form") },
        { value: FormBuilderConstants.FORM_TYPES.EVENT_TICKETS, label: this.$t("custom_form_builder.form_type_event_tickets") },
      ];
    },

    translationsNamespace() {
      return "custom_forms";
      // if (this.formType === FormBuilderConstants.FORM_TYPES.REGISTER_FORM) {
      //   return "custom_register_form";
      // } else if (this.formType === FormBuilderConstants.FORM_TYPES.EVENT_TICKETS) {
      //   return `event_ticket_form_${this.selectedEventTicketIds.join("-")}`;
      // } else {
      //   return "custom_form_translations";
      // }
    },

    filteredFields() {
      return this.fields.filter(
        it => it[FormBuilderConstants.FIELD_PROPERTIES.TYPE] !== FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS
      );
    },

    currentConfig() {
      return {
        [FormBuilderConstants.TITLE]: this.disableTitle
          ? false
          : Object.values(this.titleTranslations).filter(x => x).length
          ? FormBuilderConstants.ROOT_TRANSLATION_KEYS.TITLE_TRANSLATION_KEY
          : null,
        [FormBuilderConstants.SUBTITLE]: this.disableSubtitle
          ? false
          : Object.values(this.subtitleTranslations).filter(x => x).length
          ? FormBuilderConstants.ROOT_TRANSLATION_KEYS.SUBTITLE_TRANSLATION_KEY
          : null,
        [FormBuilderConstants.INCLUDE_TERMS_AND_GDPR]: this.includeTermsAndGdpr,
        [FormBuilderConstants.SOCIAL_LOGIN_VISIBLE]: this.socialLoginVisible,
        [FormBuilderConstants.FACEBOOK_APP_ID]: this.facebookAppId,
        [FormBuilderConstants.APPLE_CLIENT_ID]: this.appleClientId,
        [FormBuilderConstants.TRANSLATIONS_NAMESPACE]: this.translationsNamespace,
        [FormBuilderConstants.TRANSLATIONS]: this.parsedTranslations,
        [FormBuilderConstants.FIELDS]: this.parsedFields,
      };
    },

    stringifiedJsonConfig() {
      return JSON.stringify(this.currentConfig);
    },

    parsedTranslations() {
      const fieldTypes = FormBuilderConstants.FIELD_TYPES;
      let finalObject = {};
      this.filteredFields.forEach(field => {
        const prefix = field[FormBuilderConstants.FIELD_PROPERTIES.ID];

        //label translations
        if (field[FormBuilderConstants.FIELD_PROPERTIES.LABELS]) {
          Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.LABELS]).forEach(keyValue => {
            if (!(keyValue[0] in finalObject)) {
              finalObject[keyValue[0]] = {};
            }
            finalObject[keyValue[0]][`${prefix}_label`] = keyValue[1];
          });
        }

        if (
          field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.DROPDOWN ||
          field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.RADIO
        ) {
          //values translations
          if (field[FormBuilderConstants.FIELD_PROPERTIES.VALUES]) {
            Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.VALUES]).forEach(keyValue => {
              if (!(keyValue[0] in finalObject)) {
                finalObject[keyValue[0]] = {};
              }
              finalObject[keyValue[0]][`${prefix}_values`] = keyValue[1];
            });
          }
          //placeholder translations
          if (field[FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_PLACEHOLDER]) {
            Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_PLACEHOLDER]).forEach(keyValue => {
              if (!(keyValue[0] in finalObject)) {
                finalObject[keyValue[0]] = {};
              }
              finalObject[keyValue[0]][`${prefix}_placeholder`] = keyValue[1];
            });
          }
          //other input label translations
          if (field[FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_OTHER_INPUT_LABEL]) {
            Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_OTHER_INPUT_LABEL]).forEach(keyValue => {
              if (!(keyValue[0] in finalObject)) {
                finalObject[keyValue[0]] = {};
              }
              finalObject[keyValue[0]][`${prefix}_other_input_label`] = keyValue[1];
            });
          }
        }
        if (field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.CHECKBOX) {
          if (field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_SUBSTRINGS]) {
            Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_SUBSTRINGS]).forEach(keyValue => {
              if (!(keyValue[0] in finalObject)) {
                finalObject[keyValue[0]] = {};
              }

              finalObject[keyValue[0]][`${prefix}_collapsed_substrings`] = keyValue[1][FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS];
              finalObject[keyValue[0]][`${prefix}_collapsed_links`] = keyValue[1][FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS];
            });
          }
          //other input label translations
          if (field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_LABELS]) {
            Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_LABELS]).forEach(keyValue => {
              if (!(keyValue[0] in finalObject)) {
                finalObject[keyValue[0]] = {};
              }
              finalObject[keyValue[0]][`${prefix}_collapsed_text_labels`] = keyValue[1];
            });
          }
        }

        if (
          field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.CHECKBOX ||
          field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.INFORMATION
        ) {
          //substrings translations
          if (field[FormBuilderConstants.FIELD_PROPERTIES.SUBSTRINGS]) {
            Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.SUBSTRINGS]).forEach(keyValue => {
              if (!(keyValue[0] in finalObject)) {
                finalObject[keyValue[0]] = {};
              }

              finalObject[keyValue[0]][`${prefix}_substrings`] = keyValue[1][FormBuilderConstants.SUBSTRINGS_PROPERTIES.STRINGS];
              finalObject[keyValue[0]][`${prefix}_links`] = keyValue[1][FormBuilderConstants.SUBSTRINGS_PROPERTIES.LINKS];
            });
          }
        }
        if (
          (field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.INFORMATION ||
            field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === fieldTypes.CHECKBOX) &&
          field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_BUTTON_TEXT]
        ) {
          Object.entries(field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_BUTTON_TEXT]).forEach(keyValue => {
            if (!(keyValue[0] in finalObject)) {
              finalObject[keyValue[0]] = {};
            }
            finalObject[keyValue[0]][`${prefix}_button`] = keyValue[1];
          });
        }
      });

      //Form title

      if (this.titleTranslations) {
        Object.entries(this.titleTranslations).forEach(keyValue => {
          if (!(keyValue[0] in finalObject)) {
            finalObject[keyValue[0]] = {};
          }
          finalObject[keyValue[0]][FormBuilderConstants.ROOT_TRANSLATION_KEYS.TITLE_TRANSLATION_KEY] = keyValue[1];
        });
      }

      //Form subtitle
      if (this.subtitleTranslations) {
        Object.entries(this.subtitleTranslations).forEach(keyValue => {
          if (!(keyValue[0] in finalObject)) {
            finalObject[keyValue[0]] = {};
          }
          finalObject[keyValue[0]][FormBuilderConstants.ROOT_TRANSLATION_KEYS.SUBTITLE_TRANSLATION_KEY] = keyValue[1];
        });
      }

      this.$setCustomTranslations(finalObject, this.translationsNamespace);
      return finalObject;
    },

    parsedFields() {
      const fieldProperties = FormBuilderConstants.FIELD_PROPERTIES;
      const regularRegistrationFieldsIndex = this.fields.findIndex(
        field => field.type === FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS
      );
      return this.filteredFields.map(field => {
        return {
          [fieldProperties.ID]: field[fieldProperties.ID],
          //fields with negative order will be placed above default registration inputs
          [fieldProperties.ORDER]: field[fieldProperties.ORDER] - regularRegistrationFieldsIndex,
          [fieldProperties.TYPE]: field[fieldProperties.TYPE],
          [fieldProperties.REQUIRED]: field[fieldProperties.REQUIRED],
          [fieldProperties.RULES]: field[fieldProperties.RULES],
          [fieldProperties.MULTI_SELECT]: field[fieldProperties.MULTI_SELECT],
          [fieldProperties.COLLAPSED]: field[fieldProperties.COLLAPSED],
          [fieldProperties.HAS_HTML]: field[fieldProperties.HAS_HTML],
          [fieldProperties.TEXT_FIELD_OPTION_INDEX]: field[fieldProperties.TEXT_FIELD_OPTION_INDEX],
        };
      });
    },

    fieldIds() {
      return this.filteredFields.map(field => field[FormBuilderConstants.FIELD_PROPERTIES.ID]);
    },
  },
};
</script>

<style scoped>
.config-preview-box {
  word-break: break-word;
  font-size: 9px;
}

.arrow-button {
  padding: 2px !important;
  max-height: 25px;
  min-width: 40px;
}

.fields-order-text {
  font-size: 12px;
  word-break: break-all;
}
</style>
