<template>
  <div class="mb-1">
    <label class="label mb-2">Values</label>
    <div class="columns is-multiline mx-1">
      <div
        v-for="locale in supportedLocales"
        :key="locale.countryCode"
        @click="currentLocale = locale.localeCode"
        class="has-flag-button"
        :style="{ background: locale.localeCode === currentLocale ? '#BDBDBD' : '' }"
      >
        <country-flag :code="locale.countryCode"></country-flag>
      </div>
    </div>

    <div class="control mt-1">
      <template v-for="(value, index) in currentLocaleValues">
        <div :key="`${currentLocale}-${index}`" class="is-flex is-flex-direction-row is-flex">
          <input class="input mr-1" type="text" v-model="currentLocaleValues[index]" @input="setDirty"/>
          <b-button type="is-danger" class="p-1 mb-1" rounded @click="removeValuesFromIndex(index)">
            <div class="is-flex is-justify-content-center is-align-items-center">
              <close-icon class="svg-icon" style="height: 24px; width: 24px; stroke: white"></close-icon>
            </div>
          </b-button>
        </div>
      </template>
      <b-button type="is-success" class="mt-1" @click="addValue" expanded>ADD VALUE</b-button>
      <b-button
        type="is-success"
        class="mt-1"
        @click="save"
        expanded
        :disabled="isSaved"
        v-if="currentLocaleValues.length"
      >
        <template v-if="isSaved"> SAVED</template>
        <template v-else> SAVE</template>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CountryFlag from "@/web/components/shared/CountryFlag";
import CloseIcon from "@/assets/icon_close.svg";

const DEFAULT_LANG = "en";

export default {
  name: "FieldValuesEditor",

  components: { CountryFlag, CloseIcon },

  props: {
    currentFieldValues: {
      type: Object,
      required: false,
    },
  },

  data() {
    const values = this.currentFieldValues || { [DEFAULT_LANG]: [] };
    return {
      currentLocale: DEFAULT_LANG,
      values: values,
      currentLocaleValues: values[DEFAULT_LANG] || new Array(getMaxLengthFromValuesObject(values)).fill(""),
      isSaved: !!this.currentFieldValues,
    };
  },

  computed: {
    ...mapGetters("locales", ["supportedLocales"]),
  },

  methods: {
    addValue() {
      Object.values(this.values).forEach(it => it.push(""));
      this.setDirty();
    },

    removeValuesFromIndex(index) {
      Object.values(this.values).forEach(it => it.splice(index, 1));
      this.setDirty();
    },

    hasError() {
      return !Object.values(this.values)
        .map(array => {
          let allEmpty = array.every(it => !it);
          let allFilled = array.every(it => !!it);
          return allEmpty || allFilled;
        })
        .every(it => it);
    },

    save() {
      if (!this.hasError()) {
        this.isSaved = true;
        const result = Object.entries(this.values)
          .filter(([key, value]) => value.length && value.every(it => !!it))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        this.$emit("input", result);
      } else {
        this.$root.openErrorModal(
          "Something is wrong, please check all values in all languages. Every filled language has to have equal options count at correct indexes. Filled language can't have empty values.",
        );
      }
    },

    setDirty() {
      this.isSaved = false;
    },
  },

  watch: {
    currentLocale: {
      immediate: false,
      handler: function(newValue, oldValue) {
        if (oldValue) {
          this.values[oldValue] = this.currentLocaleValues;

          if (!this.values[newValue]) {
            const maxLength = getMaxLengthFromValuesObject(this.values);
            this.values[newValue] = new Array(maxLength).fill("");
          }
          this.currentLocaleValues = this.values[newValue];
        }
      },
    },
  },
};

function getMaxLengthFromValuesObject(valuesObject) {
  return Object.values(valuesObject).reduce((acc, valuesArray) => {
    if (valuesArray.length > acc) {
      return valuesArray.length;
    } else {
      return acc;
    }
  }, 0);
}
</script>

<style scoped lang="scss">
.has-flag-button {
  padding: 4px;
  cursor: pointer;
  border: #828282 1px solid;
  border-radius: 4px;
  margin: 2px;
  background-clip: border-box;

  &:hover {
    background: #c0c0c0;
  }

  img {
    display: block;
    width: 26px;
    height: auto;
  }
}
</style>
