<template>
  <div>
    <div class="is-flex is-flex-direction-row">
      <label class="label mb-1">Field rules</label>
      <help-icon class="svg-icon help-button" @click="openHelpModal"></help-icon>
    </div>

    <div class="mb-1" v-for="(rule, index) in rules" :key="`${rule}-${index}`">
      <div class="columns is-gapless is-mobile is-vcentered">
        <div class="column">
          <div class="is-size-8 mb-1 box">
            <div>{{ `type: ${rule.type}` }}</div>
            <div>{{ `field_id: ${rule.field_id}` }}</div>
            <div>{{ `include: ${rule.include}, exclude: ${rule.exclude}` }}</div>
          </div>
        </div>
        <div class="column is-narrow ml-1">
          <div class="is-flex is-flex-direction-column">
            <b-button type="is-warning" class="is-block px-1" style="max-width: 40px" @click="editRule(index)" v-if="!ruleEditorVisible">
              <edit-icon class="svg-icon"></edit-icon>
            </b-button>
            <b-button type="is-danger" class="is-block px-1 mt-1" style="max-width: 40px" @click="removeRule(index)">
              <trash-bin-icon class="svg-icon"></trash-bin-icon>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <b-button type="is-success" class="mb-1" expanded v-if="!ruleEditorVisible" @click="showRuleEditor">ADD NEW RULE</b-button>

    <field-rules-editor
      v-if="ruleEditorVisible"
      :current-field-id="currentFieldId"
      :rule-to-edit="ruleToEdit"
      :rule-id="lastIndex"
      :all-fields="allFields"
      @submit="upsertRule"
      @cancel="cancelEdit"
    ></field-rules-editor>
  </div>
</template>

<script>
import HelpIcon from "@/assets/navbar/help-icon.svg";
import FieldRulesEditor from "@/web/views/dev/formbuilder/FieldRulesEditor";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import EditIcon from "@/assets/icons/edit_2.svg";

export default {
  name: "FieldRules",

  components: { FieldRulesEditor, HelpIcon, TrashBinIcon, EditIcon },

  props: {
    allFields: {
      type: Array,
    },

    currentFieldId: {
      type: String,
      required: true,
    },

    currentRules: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      rules: this.currentRules || [],
      lastIndex: (this.currentRules && this.currentRules.length - 1) || -1,
      ruleEditorVisible: false,
      ruleToEdit: null,
      error: false,
    };
  },

  computed: {},

  watch: {
    rules(newValue) {
      this.$emit("input", newValue);
    },
  },

  methods: {
    showRuleEditor() {
      this.lastIndex = this.lastIndex + 1;
      this.ruleEditorVisible = true;
    },

    upsertRule(updatedRule) {
      const foundIndex = this.rules.findIndex(rule => rule.id === updatedRule.id);
      if (foundIndex >= 0) {
        this.rules[foundIndex] = updatedRule;
      } else {
        this.rules.push(updatedRule);
      }
      this.ruleEditorVisible = false;
      this.ruleToEdit = null;
    },

    editRule(index) {
      this.ruleToEdit = this.rules[index];
      this.ruleEditorVisible = true;
    },

    cancelEdit() {
      this.ruleEditorVisible = false;
    },

    removeRule(index) {
      this.rules.splice(index, 1);
    },

    openHelpModal() {
      this.$root.openInfoModal({
        title: "Rules instruction",
        message:
          "locale: \n include - field will be visible only in these locales \n exclude - field won't be visible only in these locales" +
          "\n\n" +
          "field_value_text: \n Can be used with text, text_area, email, date, phone_number, country inputs. \n  field_id - related field to compare \n exclude - field will be visible only when related field value is not equal to inserted values \n include - field will be visible only when related field value is equal to inserted values" +
          "\n\n" +
          "field_not_empty \n field_id - field will be visible when this related field is not empty \n" +
          "\n\n" +
          "field_value_true/field_value_false: \n Can be used with checkbox input. \n field_id - related field to compare" +
          "\n\n" +
          'field_value_index: \n Can be used with dropdown, radio inputs. \n  field_id - related field to compare \n exclude - field will be visible only when related field selected index is not equal to inserted values \n include - field will be visible only when related field selected index is equal to inserted values"',

        imageUrl: "https://wdupiu.pl/img/121756.jpg",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.help-button {
  max-width: 24px;
  max-height: 24px;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: #c0c0c0;
  }
}
</style>
