<template>
  <div>
    <!-- RULE TYPE SELECTION-->
    <div class="columns is-multiline is-centered is-variable is-1">
      <div class="column is-narrow" v-for="rule in supportedFieldRules" :key="rule">
        <div class="rule-button has-text-centered" @click="type = rule" :style="{ background: type === rule ? '#BDBDBD' : '' }">
          {{ rule }}
        </div>
      </div>
    </div>

    <template v-if="type === FormBuilderConstants.RULE_TYPES.LOCALE">
      <div class="is-flex is-flex-direction-row">
        <div class="is-size-7">{{ $t("custom_form_builder.exclude") }}</div>
        <b-switch class="ml-1" size="is-small" v-model="isInclude"></b-switch>
        <div class="is-size-7">{{ $t("custom_form_builder.include") }}</div>
      </div>

      <countries-autocomplete
        class="mt-1"
        :multi-select="true"
        :label="includeExcludeInputLabel"
        :select-locale="true"
        v-model="includeExclude"
      >
      </countries-autocomplete>
    </template>
    <template v-else-if="type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE_INDEX">
      <label class="label mt-1">{{ $t("custom_form_builder.value_index_rule_field_id_label") }}</label>
      <dropdown-select-wrapper :items="allFieldIds" v-model="fieldId"></dropdown-select-wrapper>

      <div class="is-flex is-flex-direction-row mt-1">
        <div class="is-size-7">{{ $t("custom_form_builder.exclude") }}</div>
        <b-switch class="ml-1" size="is-small" v-model="isInclude"></b-switch>
        <div class="is-size-7">{{ $t("custom_form_builder.include") }}</div>
      </div>

      <template v-if="fieldId">
        <label class="label mt-1">{{ includeExcludeInputLabel }}</label>
        <dropdown-select-wrapper
          :items="selectedDropdownValues"
          :value="selectedDropdownValues[includeExclude]"
          @input="selectDropdownValueIndex"
        ></dropdown-select-wrapper>
      </template>
    </template>

    <template v-else-if="type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE">
      <label class="label mt-1">{{ $t("custom_form_builder.value_rule_field_id_label") }}</label>
      <dropdown-select-wrapper :items="allFieldIds" v-model="fieldId"></dropdown-select-wrapper>

      <div class="is-flex is-flex-direction-row mt-1">
        <div class="is-size-7">{{ $t("custom_form_builder.exclude") }}</div>
        <b-switch class="ml-1" size="is-small" v-model="isInclude"></b-switch>
        <div class="is-size-7">{{ $t("custom_form_builder.include") }}</div>
      </div>

      <label class="label mt-1">{{ includeExcludeInputLabel }}</label>
      <input class="input" type="text" v-model="includeExclude" />
    </template>

    <template v-else-if="type === FormBuilderConstants.RULE_TYPES.FIELD_NOT_EMPTY">
      <label class="label mt-1">{{ $t("custom_form_builder.value_rule_field_id_label") }}</label>
      <dropdown-select-wrapper :items="allFieldIds" v-model="fieldId"></dropdown-select-wrapper>
    </template>

    <template v-else-if="type === FormBuilderConstants.RULE_TYPES.FIELD_BOOLEAN_VALUE">
      <label class="label mt-1">{{ $t("custom_form_builder.value_rule_field_id_label") }}</label>
      <dropdown-select-wrapper :items="allFieldIds" v-model="fieldId"></dropdown-select-wrapper>

      <div class="is-flex is-flex-direction-row mt-1">
        <div class="is-size-7">{{ $t("custom_form_builder.boolean_value_rule_false_label") }}</div>
        <b-switch class="ml-1" size="is-small" v-model="includeExclude"></b-switch>
        <div class="is-size-7">{{ $t("custom_form_builder.boolean_value_rule_true_label") }}</div>
      </div>
    </template>

    <template v-else-if="type === FormBuilderConstants.RULE_TYPES.FIELD_COUNTRY">
      <label class="label mt-1">{{ $t("custom_form_builder.value_rule_field_id_label") }}</label>
      <dropdown-select-wrapper :items="allFieldIds" v-model="fieldId"></dropdown-select-wrapper>

      <div class="is-flex is-flex-direction-row mt-1">
        <div class="is-size-7">{{ $t("custom_form_builder.exclude") }}</div>
        <b-switch class="ml-1" size="is-small" v-model="isInclude"></b-switch>
        <div class="is-size-7">{{ $t("custom_form_builder.include") }}</div>
      </div>

      <countries-autocomplete :multi-select="true" :label="includeExcludeInputLabel" v-model="includeExclude"></countries-autocomplete>
    </template>

    <template v-else-if="type === FormBuilderConstants.RULE_TYPES.FIELD_DATE">
      <label class="label mt-1">{{ $t("custom_form_builder.value_rule_field_id_label") }}</label>
      <dropdown-select-wrapper :items="allFieldIds" v-model="fieldId"></dropdown-select-wrapper>

      <div class="is-flex is-flex-direction-row mt-1">
        <div class="is-size-7">{{ $t("custom_form_builder.field_date_rule_include_label") }}</div>
        <b-switch class="ml-1" size="is-small" v-model="isInclude"></b-switch>
        <div class="is-size-7">{{ $t("custom_form_builder.field_date_rule_exclude_label") }}</div>
      </div>

      <input class="input mt-1" type="date" v-model="includeExclude" />
    </template>

    <div class="is-flex is-flex-direction-row mt-2">
      <b-button type="is-success" class="mr-1" expanded @click="save" :disabled="$v.$invalid">SAVE</b-button>
      <b-button type="is-danger" class="ml-1" expanded @click="cancel">CANCEL</b-button>
    </div>
  </div>
</template>

<script>
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";
import { required } from "vuelidate/lib/validators";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import CountriesAutocomplete from "@/web/components/profile/CountriesAutocomplete";

export default {
  name: "FieldRulesEditor",

  components: {
    DropdownSelectWrapper,
    CountriesAutocomplete,
  },

  props: {
    allFields: {
      type: Array,
      required: true,
    },
    currentFieldId: {
      type: String,
      required: true,
    },
    ruleId: {
      type: Number,
      required: true,
    },
    ruleToEdit: {
      type: Object,
      required: false,
    },
  },

  data() {
    let fieldId, include, exclude;
    if (this.ruleToEdit) {
      include = (this.ruleToEdit.include && this.ruleToEdit.include.join(",")) || "";
      exclude = (this.ruleToEdit.exclude && this.ruleToEdit.exclude.join(",")) || "";
      fieldId = this.ruleToEdit.field_id;
    } else {
      fieldId = null;
      include = "";
      exclude = "";
    }
    return {
      submitted: false,
      type: (this.ruleToEdit && this.ruleToEdit.type) || null,
      fieldId: fieldId,
      isInclude: !this.ruleToEdit || !!include,
      includeExclude: include || exclude,
    };
  },

  validations() {
    const ruleTypes = FormBuilderConstants.RULE_TYPES;
    if (this.type === ruleTypes.LOCALE) {
      return {
        type: { required },
        includeExclude: { required },
      };
    } else if (this.type === ruleTypes.FIELD_NOT_EMPTY) {
      return {
        type: { required },
        fieldId: { required },
      };
    } else if (this.type === ruleTypes.FIELD_VALUE) {
      return {
        type: { required },
        fieldId: { required },
        includeExclude: { required },
      };
    } else if (this.type === ruleTypes.FIELD_BOOLEAN_VALUE) {
      return {
        type: { required },
        fieldId: { required },
      };
    } else if (this.type === ruleTypes.FIELD_VALUE_INDEX) {
      return {
        type: { required },
        fieldId: { required },
        includeExclude: { required },
      };
    } else if (this.type === ruleTypes.FIELD_COUNTRY) {
      return {
        type: { required },
        fieldId: { required },
        includeExclude: { required },
      };
    } else {
      return {
        type: { required },
      };
    }
  },

  computed: {
    FormBuilderConstants: () => FormBuilderConstants,

    supportedFieldRules() {
      return Object.values(FormBuilderConstants.RULE_TYPES);
    },

    allFieldIds() {
      return this.allFields
        .filter(field => {
          if (this.currentFieldId === field.id) {
            return false;
          }
          if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE_INDEX) {
            return field.type === FormBuilderConstants.FIELD_TYPES.RADIO || field.type === FormBuilderConstants.FIELD_TYPES.DROPDOWN;
          } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE) {
            return (
              field.type === FormBuilderConstants.FIELD_TYPES.TEXT ||
              field.type === FormBuilderConstants.FIELD_TYPES.TEXT_AREA ||
              field.type === FormBuilderConstants.FIELD_TYPES.EMAIL
            );
          } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_BOOLEAN_VALUE) {
            return field.type === FormBuilderConstants.FIELD_TYPES.CHECKBOX;
          } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_COUNTRY) {
            return field.type === FormBuilderConstants.FIELD_TYPES.COUNTRY;
          } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_DATE) {
            return field.type === FormBuilderConstants.FIELD_TYPES.DATE;
          } else {
            return field.type !== FormBuilderConstants.FIELD_TYPES.INFORMATION;
          }
        })
        .map(it => it.id);
    },

    selectedDropdownValues() {
      return Object.values(this.allFields.find(field => field.id === this.fieldId).values)[0];
    },

    includeExcludeInputLabel() {
      if (this.type === FormBuilderConstants.RULE_TYPES.LOCALE) {
        if (this.isInclude) {
          return this.$t("custom_form_builder.locale_rule_include_label");
        } else {
          return this.$t("custom_form_builder.locale_rule_exclude_label");
        }
      } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE_INDEX) {
        if (this.isInclude) {
          return this.$t("custom_form_builder.value_index_rule_value_include_label");
        } else {
          return this.$t("custom_form_builder.value_index_rule_value_exclude_label");
        }
      } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE) {
        if (this.isInclude) {
          return this.$t("custom_form_builder.value_text_rule_include_label");
        } else {
          return this.$t("custom_form_builder.value_text_rule_exclude_label");
        }
      } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_COUNTRY) {
        if (this.isInclude) {
          return this.$t("custom_form_builder.field_country_rule_include_label");
        } else {
          return this.$t("custom_form_builder.field_country_rule_exclude_label");
        }
      } else {
        return null;
      }
    },
  },

  methods: {
    selectDropdownValueIndex(input) {
      this.includeExclude = this.selectedDropdownValues.indexOf(input);
    },

    save() {
      this.$v.$touch();
      this.$data.submitted = true;

      let includeExclude;
      if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_VALUE_INDEX) {
        includeExclude = (this.includeExclude !== null && [this.includeExclude]) || null;
      } else if (this.type === FormBuilderConstants.RULE_TYPES.FIELD_BOOLEAN_VALUE) {
        includeExclude = [this.includeExclude];
      } else {
        includeExclude = (this.includeExclude && this.includeExclude.split(",")) || null;
      }

      if (!this.$v.$invalid) {
        const newRule = {
          id: (this.ruleToEdit && this.ruleToEdit.id) || this.ruleId,
          type: this.type,
          field_id: this.fieldId,
          include: this.isInclude ? includeExclude : null,
          exclude: this.isInclude ? null : includeExclude,
        };
        this.$emit("submit", newRule);
      }
    },

    cancel() {
      this.$emit("cancel");
    },
  },

  watch: {
    type(newValue) {
      this.includeExclude = "";
      this.fieldId = null;
      if (newValue === FormBuilderConstants.RULE_TYPES.FIELD_BOOLEAN_VALUE) {
        this.includeExclude = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rule-button {
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  border: #828282 1px solid;
  border-radius: 4px;
  background-clip: border-box;

  &:hover {
    background: #828282;
  }
}
</style>
