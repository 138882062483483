<template>
  <div class="box my-1">
    <div class="field mb-1">
      <div class="is-flex is-flex-direction-row">
        <label class="label mb-1">Input type</label>
        <help-icon class="svg-icon help-button" @click="openInputTypesHelpModal"></help-icon>
      </div>
      <dropdown-select :items="fieldTypesItems" :value="fieldType" @input="selectFieldType" :disabled="isIdAndTypeDisabled">
        <template #dropdown="{ item }">
          <span>{{ item }}</span>
        </template>

        <template #placeholder>
          <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
        </template>
      </dropdown-select>
      <template v-if="isIdAndTypeDisabled">
        <p class="help is-warning">{{ $t("custom_form_builder.field_error_id_type_rule_dependency_edit") }}</p>
      </template>
    </div>

    <div class="control mb-1">
      <label class="label mb-1">Field id</label>
      <input class="input" type="text" placeholder="Field Id" v-model="fieldId" :disabled="isIdAndTypeDisabled" />
      <template v-if="isIdAndTypeDisabled">
        <p class="help is-warning">{{ $t("custom_form_builder.field_error_id_type_rule_dependency_edit") }}</p>
      </template>
    </div>

    <div class="field mb-1" v-if="currentFieldType !== fieldTypes.INFORMATION">
      <label class="checkbox consents">
        <checkbox class="checkbox-input" v-model="required"></checkbox>
        <span class="is-size-8" style="margin-top: 2px" @click="required = !required">Required field</span>
      </label>
    </div>

    <template v-if="currentFieldType === fieldTypes.COUNTRY">
      <div class="field mb-1">
        <label class="checkbox consents">
          <checkbox class="checkbox-input" v-model="countryMultiSelect"></checkbox>
          <span class="is-size-8" style="margin-top: 2px" @click="countryMultiSelect = !countryMultiSelect">Multi select</span>
        </label>
      </div>
    </template>

    <template v-if="currentFieldType === fieldTypes.CHECKBOX">
      <div class="field mb-1">
        <label class="checkbox consents">
          <checkbox class="checkbox-input" v-model="informationCollapsed"></checkbox>
          <span class="is-size-8" style="margin-top: 2px" @click="informationCollapsed = !informationCollapsed">Collapsable</span>
        </label>
      </div>

      <field-label-editor
        v-if="informationCollapsed"
        @input="updateInformationCollapseButtonTranslations"
        title="Collapse button text"
        :current-label-translations="informationCollapseButtonTranslations"
      ></field-label-editor>

      <field-label-editor
        @input="updateCollapsedTextLabels"
        title="Collapsed text"
        :current-label-translations="collapsedTextLabels"
      ></field-label-editor>

      <field-checkbox-substrings
        @input="updateCollapsedTextSubstrings"
        :current-substrings="collapsedTextSubstrings"
      ></field-checkbox-substrings>
    </template>

    <template v-if="currentFieldType === fieldTypes.INFORMATION">
      <div class="field mb-1">
        <label class="checkbox consents">
          <checkbox class="checkbox-input" v-model="informationHasHtml"></checkbox>
          <span class="is-size-8" style="margin-top: 2px" @click="informationHasHtml = !informationHasHtml">Has HTML in label</span>
        </label>
      </div>

      <div class="field mb-1">
        <label class="checkbox consents">
          <checkbox class="checkbox-input" v-model="informationCollapsed"></checkbox>
          <span class="is-size-8" style="margin-top: 2px" @click="informationCollapsed = !informationCollapsed">Collapsable</span>
        </label>
      </div>

      <field-label-editor
        v-if="informationCollapsed"
        @input="updateInformationCollapseButtonTranslations"
        title="Collapse button text"
        :current-label-translations="informationCollapseButtonTranslations"
      ></field-label-editor>
    </template>

    <field-label-editor @input="updateLabelTranslations" title="Label" :current-label-translations="labelTranslations"></field-label-editor>

    <template v-if="currentFieldType === fieldTypes.DROPDOWN">
      <field-label-editor
        @input="updateDropdownPlaceholderTranslations"
        title="Dropdown placeholder"
        :current-label-translations="dropdownPlaceholderTranslations"
      ></field-label-editor>
    </template>

    <field-rules @input="updateRules" :all-fields="allFields" :current-rules="rules" :current-field-id="fieldId"></field-rules>

    <template v-if="currentFieldType === fieldTypes.DROPDOWN || currentFieldType === fieldTypes.RADIO">
      <field-values-editor @input="updateValues" :current-field-values="values"></field-values-editor>
      <div class="control mb-1" v-if="currentFieldType === fieldTypes.DROPDOWN">
        <label class="label mb-1">Dropdown 'other' option index</label>
        <input class="input" type="number" placeholder="Index" v-model="dropdownOtherIndex" />
        <div v-if="$v.dropdownOtherIndex.isCorrect" class="has-text-danger">Error: Wrong index</div>
      </div>
    </template>

    <template v-if="currentFieldType === fieldTypes.DROPDOWN && isNumeric(dropdownOtherIndex)">
      <field-label-editor
        @input="updateDropdownOtherInputLabelTranslations"
        title="Other option input label"
        :current-label-translations="dropdownOtherLabelTranslations"
      ></field-label-editor>
    </template>

    <template v-if="currentFieldType === fieldTypes.CHECKBOX || currentFieldType === fieldTypes.INFORMATION">
      <field-checkbox-substrings @input="updateSubstrings" :current-substrings="substrings"></field-checkbox-substrings>
    </template>
  </div>
</template>

<script>
import DropdownSelect from "@/shared/components/DropdownSelect";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import FieldLabelEditor from "@/web/views/dev/formbuilder/FieldLabelEditor";
import Checkbox from "@/shared/components/form/Checkbox";
import FieldValuesEditor from "@/web/views/dev/formbuilder/FieldValuesEditor";
import FieldRules from "@/web/views/dev/formbuilder/FieldRules";
import FieldCheckboxSubstrings from "@/web/views/dev/formbuilder/FieldCheckboxSubstrings";
import HelpIcon from "@/assets/navbar/help-icon.svg";
import FormBuilderInputTypesHelpModal from "@/web/views/dev/formbuilder/FormBuilderInputTypesHelpModal";

export default {
  name: "CustomFieldEditor",

  components: {
    FieldCheckboxSubstrings,
    FieldRules,
    FieldValuesEditor,
    FieldLabelEditor,
    DropdownSelect,
    Checkbox,
    HelpIcon,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    order: {
      type: Number,
      required: true,
    },

    allFields: {
      type: Array,
    },
  },

  validations() {
    return {
      dropdownOtherIndex: {
        isCorrect: function (v) {
          if (v && this.dropdownValues) {
            const num = Number.parseInt(v);
            const dropdownValues = Object.values(this.dropdownValues);
            return !(dropdownValues.length && num < dropdownValues[0].length && num >= 0);
          } else {
            return false;
          }
        },
      },
    };
  },

  data() {
    const fieldTypes = Object.values(FormBuilderConstants.FIELD_TYPES).filter(
      fieldType => fieldType !== FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS
    );
    return {
      //field config
      fieldType: fieldTypes.indexOf(this.field[FormBuilderConstants.FIELD_PROPERTIES.TYPE]),
      fieldId: this.field[FormBuilderConstants.FIELD_PROPERTIES.ID],
      labelTranslations: this.field[FormBuilderConstants.FIELD_PROPERTIES.LABELS],
      required: this.field[FormBuilderConstants.FIELD_PROPERTIES.REQUIRED] || false,
      rules: this.field[FormBuilderConstants.FIELD_PROPERTIES.RULES],
      values: this.field[FormBuilderConstants.FIELD_PROPERTIES.VALUES],
      dropdownOtherIndex: this.field[FormBuilderConstants.FIELD_PROPERTIES.TEXT_FIELD_OPTION_INDEX],
      dropdownOtherLabelTranslations: this.field[FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_OTHER_INPUT_LABEL],
      dropdownPlaceholderTranslations: this.field[FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_PLACEHOLDER],
      countryMultiSelect: this.field[FormBuilderConstants.FIELD_PROPERTIES.MULTI_SELECT] || false,
      substrings: this.field[FormBuilderConstants.FIELD_PROPERTIES.SUBSTRINGS],
      collapsedTextLabels: this.field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_LABELS],
      collapsedTextSubstrings: this.field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_SUBSTRINGS],
      informationHasHtml: this.field[FormBuilderConstants.FIELD_PROPERTIES.HAS_HTML] || false,
      informationCollapsed: this.field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED] || false,
      informationCollapseButtonTranslations: this.field[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_BUTTON_TEXT],
      //other
      error: false,
    };
  },

  computed: {
    fieldTypes: () => FormBuilderConstants.FIELD_TYPES,

    fieldTypesItems() {
      return Object.values(FormBuilderConstants.FIELD_TYPES).filter(
        fieldType => fieldType !== FormBuilderConstants.FIELD_TYPES.REGULAR_REGISTER_FORM_FIELDS
      );
    },

    currentFieldType() {
      if (this.fieldType !== null) {
        return this.fieldTypesItems[this.fieldType];
      } else {
        return null;
      }
    },

    resultField() {
      const commonProperties = {
        [FormBuilderConstants.FIELD_PROPERTIES.ID]: this.fieldId,
        [FormBuilderConstants.FIELD_PROPERTIES.ORDER]: this.order,
        [FormBuilderConstants.FIELD_PROPERTIES.TYPE]: this.currentFieldType,
        [FormBuilderConstants.FIELD_PROPERTIES.LABELS]: this.labelTranslations,
        [FormBuilderConstants.FIELD_PROPERTIES.REQUIRED]: this.required,
        [FormBuilderConstants.FIELD_PROPERTIES.RULES]: this.rules,
      };
      if (this.currentFieldType === this.fieldTypes.CHECKBOX) {
        return {
          ...commonProperties,
          [FormBuilderConstants.FIELD_PROPERTIES.SUBSTRINGS]: this.substrings,
          [FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_SUBSTRINGS]: this.collapsedTextSubstrings,
          [FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_TEXT_LABELS]: this.collapsedTextLabels,
          [FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED]: this.informationCollapsed,
          [FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_BUTTON_TEXT]: this.informationCollapseButtonTranslations,
        };
      } else if (this.currentFieldType === this.fieldTypes.COUNTRY) {
        return {
          ...commonProperties,
          [FormBuilderConstants.FIELD_PROPERTIES.MULTI_SELECT]: this.countryMultiSelect,
        };
      } else if (this.currentFieldType === this.fieldTypes.DROPDOWN) {
        return {
          ...commonProperties,
          [FormBuilderConstants.FIELD_PROPERTIES.VALUES]: this.values,
          [FormBuilderConstants.FIELD_PROPERTIES.TEXT_FIELD_OPTION_INDEX]: this.dropdownOtherIndex,
          [FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_PLACEHOLDER]: this.dropdownPlaceholderTranslations,
          [FormBuilderConstants.FIELD_PROPERTIES.DROPDOWN_OTHER_INPUT_LABEL]: this.dropdownOtherLabelTranslations,
        };
      } else if (this.currentFieldType === this.fieldTypes.RADIO) {
        return {
          ...commonProperties,
          [FormBuilderConstants.FIELD_PROPERTIES.VALUES]: this.values,
        };
      } else if (this.currentFieldType === this.fieldTypes.INFORMATION) {
        return {
          ...commonProperties,
          [FormBuilderConstants.FIELD_PROPERTIES.SUBSTRINGS]: this.substrings,
          [FormBuilderConstants.FIELD_PROPERTIES.REQUIRED]: false,
          [FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED]: this.informationCollapsed,
          [FormBuilderConstants.FIELD_PROPERTIES.HAS_HTML]: this.informationHasHtml,
          [FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED_BUTTON_TEXT]: this.informationCollapseButtonTranslations,
        };
      } else {
        return commonProperties;
      }
    },

    isIdAndTypeDisabled() {
      if (this.allFields && this.allFields.length) {
        return !this.allFields.every(field => {
          let rules = field[FormBuilderConstants.FIELD_PROPERTIES.RULES];
          if (rules && rules.length) {
            return rules.every(rule => rule[FormBuilderConstants.RULE_PROPERTIES.FIELD_ID] !== this.fieldId);
          } else {
            return true;
          }
        });
      } else {
        return false;
      }
    },
  },

  methods: {
    selectFieldType(value) {
      this.fieldType = value;
    },

    updateLabelTranslations(labelTranslations) {
      this.labelTranslations = { ...labelTranslations };
    },

    updateCollapsedTextLabels(labelTranslations) {
      this.collapsedTextLabels = {...labelTranslations};
    },

    updateInformationCollapseButtonTranslations(translations) {
      this.informationCollapseButtonTranslations = { ...translations };
    },

    updateDropdownPlaceholderTranslations(translations) {
      this.dropdownPlaceholderTranslations = { ...translations };
    },

    updateDropdownOtherInputLabelTranslations(translations) {
      this.dropdownOtherLabelTranslations = { ...translations };
    },

    updateRules(updatedRules) {
      this.rules = updatedRules;
    },

    updateValues(updatesValues) {
      this.values = { ...updatesValues };
    },

    updateSubstrings(updatedSubstrings) {
      this.substrings = { ...updatedSubstrings };
    },

    updateCollapsedTextSubstrings(updatedSubstrings) {
      this.collapsedTextSubstrings = { ...updatedSubstrings };
    },

    openInputTypesHelpModal() {
      this.$buefy.modal.open({
        parent: this,
        component: FormBuilderInputTypesHelpModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
      });
    },

    isNumeric(str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    },
  },

  watch: {
    fieldType: {
      immediate: false,
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.dropdownValues = null;
        }
      },
    },

    resultField: {
      immediate: true,
      handler: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.help-button {
  max-width: 24px;
  max-height: 24px;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: #c0c0c0;
  }
}
</style>
