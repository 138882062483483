<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">Input types</h1>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot has-text-left">
      <div class="has-fullwidth">
        <div class="field">
          <label class="label has-text-primary">text</label>
          <div class="control">
            <input class="input is-simple" type="text" placeholder="Text input" />
          </div>
        </div>

        <div class="control mt-1">
          <label class="label mb-1 has-text-primary">textarea</label>
          <textarea class="input" type="text" style="min-height: 100px" placeholder="Textarea input" />
        </div>

        <div class="field mt-1">
          <label class="label has-text-primary">phone_number</label>
          <div class="columns mb-0 pb-0 is-mobile is-gapless">
            <div class="column is-one-quarter mr-1">
              <number-phone-prefix-select></number-phone-prefix-select>
            </div>
            <div class="column">
              <div class="control">
                <input class="input is-simple" type="tel" placeholder="Phone number input" />
              </div>
            </div>
          </div>
        </div>

        <div class="field mt-1">
          <label class="label mb-1 has-text-primary">dropdown</label>
          <dropdown-select :items="['item0', 'item1', 'item2']">
            <template #dropdown="{ item }">
              <span>{{ item }}</span>
            </template>

            <template #placeholder>
              <span class="has-text-secondary">{{ $t("common.select_from_list") }}</span>
            </template>
          </dropdown-select>
        </div>

        <div class="field">
          <div class="control">
            <label class="checkbox consents">
              <checkbox class="checkbox-input" v-model="checkbox"></checkbox>
              <span class="consents">checkbox</span>
            </label>
          </div>
        </div>

        <countries-autocomplete class="mb-2" :multi-select="false"></countries-autocomplete>

        <label class="label has-text-primary mt-2">date</label>
        <div class="control">
          <input class="input is-simple" type="date" placeholder="2020.04.20" />
        </div>

        <label class="label has-text-primary mt-2">email</label>
        <div class="control">
          <input class="input is-simple" type="email" placeholder="email" />
        </div>

        <label class="label has-text-primary mt-2">radio</label>
        <div class="control">
          <div class="is-flex is-flex-direction-row is-align-items-center mt-1">
            <radio-button class="mr-1" :is-primary="true" :value="true" :size="24"></radio-button>
            <div class="label" style="cursor: pointer">radio0</div>
          </div>
          <div class="is-flex is-flex-direction-row is-align-items-center mt-1">
            <radio-button class="mr-1" :is-primary="true" :value="false" :size="24"></radio-button>
            <div class="label" style="cursor: pointer">radio1</div>
          </div>
        </div>

        <label class="label has-text-primary mt-2">information</label>
        <span class="is-size-7">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum.
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import NumberPhonePrefixSelect from "@/web/components/forms/NumberPhonePrefixSelect";
import DropdownSelect from "@/shared/components/DropdownSelect";
import Checkbox from "@/shared/components/form/Checkbox";
import CountriesAutocomplete from "@/web/components/profile/CountriesAutocomplete";
import RadioButton from "@/shared/components/form/RadioButton";

export default {
  name: "FormBuilderInputTypesHelpModal",

  props: ["errorMessage"],

  data() {
    return {
      checkbox: false,
    };
  },

  components: { CloseIcon, NumberPhonePrefixSelect, DropdownSelect, Checkbox, CountriesAutocomplete, RadioButton },

  computed: {},
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
    width: 100%;
    height: 100%;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: bold;
}

.consents {
  font-size: 13px;
}
</style>
